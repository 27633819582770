/* stylelint-disable */
import { css, SerializedStyles } from '@emotion/react';

export const intercomChat = (): SerializedStyles => css`
    .intercom-lightweight-app-launcher, .intercom-namespace .intercom-with-namespace-52k34s {
        bottom: 100px !important;

        @media(min-width: 1024px){
            bottom: 20px !important;
        }
    }
`;
